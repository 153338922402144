import { navigateToPath } from 'AppNavigation/navigateToPath'
import { getHistory } from 'AppNavigation/history'
import { dispatch } from 'AppStore/index'
import * as ActionTypes from 'AppActions/types'
import { NavItem } from 'AppModels'
import { isString } from 'lodash'

/**
 * Called when you want to navigate to another route path
 * @param {string|NavItem} item - path you want to route to or the navItem object
 * 
 * @returns {void}
 */
export const navigateTo = (item:string | NavItem) => {

  let path = ''
  let pageName
  if (isString(item)) {
    path = item
  }
  else {
    path = item.routePath
    pageName = item.name
  }

  const { success, message } = navigateToPath(path, getHistory())
  
  success
    ? dispatch({
      type: ActionTypes.NAVIGATE_TO_PAGE,
      data: {
        name: pageName
      }
    })
    : console.log(message)

}