import React from 'react'
import { View, Linking } from 'react-native'
import { IconCard,
  IIconCard } from 'AppComponents'
import { IDimensionsState } from 'AppReducers'
import { useSelector } from 'react-redux'

const contactItems:IIconCard[] = [
  {
    icon: 'map-marker-alt',
    title: 'Mailing Address',
    info: 'RLGA Technical Services\n5902 East Grandview Road\nScottsdale AZ 85254-1287',
    titleStyle: {
      fontSize: 25,
      color: '#ff0000'
    },
    infoStyle: {
      fontSize: 18
    }
  },
  {
    icon: 'phone',
    title: 'Phone Number',
    info: '(602) 569-9645',
    titleStyle: {
      fontSize: 25,
      color: '#ff0000'
    },
    infoStyle: {
      fontSize: 18
    }
  },
  {
    icon: 'envelope', 
    title: 'Email', 
    info: 'info@specsandcodes.com', 
    titleStyle: {
      fontSize: 25,
      color: '#ff0000'
    }, 
    infoStyle: {
      fontSize: 18,
      textDecorationLine: 'underline'
    },
    onPress: () => {
      Linking.openURL('mailto:info@specsandcodes.com?subject=')
    }
  },
  {
    icon: 'clock',
    title: 'Office Hours',
    info: 'Monday through Friday\n8:00 AM to 5:00 PM MST',
    titleStyle: {
      fontSize: 25,
      color: '#ff0000'
    },
    infoStyle: {
      fontSize: 18
    }
  }
]

const setupContactCards = (styles:any) => {

  return contactItems.map((item, index) => {
    return (
      <IconCard
        key={index}
        title={item.title}
        info={item.info}
        icon={item.icon}
        iconSize={item.iconSize}
        style={styles}
        titleStyle={item.titleStyle}
        infoStyle={item.infoStyle}
        onPress={item.onPress}
      />
    )
  })
}

export const ContactContainer = (props: any) => {
    
  const { style } = props
  const state = useSelector((state:any) => state)
  const dimensions: IDimensionsState = state.dimensionsReducer

  return (
    <View
      style={{
        ...style,
        ...styles.container
      }}
    >
      <View
        style={{
          ...styles.contactItems,
          flexDirection: dimensions.shouldDisplayMobile ? 'column' :'row', 
        }}
      >
        {
          setupContactCards({
            width: dimensions.shouldDisplayMobile ? '100%' : '25%', 
            height: 200
          })
        }
      </View>      
        
    </View>
  )
}

const styles = {
  container: {
    flex:1, 
  },
  title: {
    color: '#ff0000', 
    fontSize: 30,
    paddingBottom: 8
  },
  contactItems: {
    flexWrap: 'wrap'
  }
}
