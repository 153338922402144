const INIT_APP = 'INIT_APP'
const UPDATE_ORIENTATION = 'UPDATE_ORIENTATION'
const UPDATE_DIMENSIONS = 'UPDATE_DIMENSIONS'

const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU'

const ASK_CODE_FORM_SUBMIT = 'ASK_CODE_FORM_SUBMIT'
const ASK_CODE_ON_INPUT_CHANGE = 'ASK_CODE_ON_INPUT_CHANGE'
const ASK_CODE_CLEAR_INPUT = 'ASK_CODE_CLEAR_INPUT'

const NAVIGATE_TO_PAGE = 'NAVIGATE_TO_PAGE'

const TOGGLE_MODAL = 'TOGGLE_MODAL'

export { 
  INIT_APP,
  UPDATE_ORIENTATION,
  UPDATE_DIMENSIONS,
  TOGGLE_SIDE_MENU,
  ASK_CODE_FORM_SUBMIT,
  ASK_CODE_ON_INPUT_CHANGE,
  ASK_CODE_CLEAR_INPUT,
  NAVIGATE_TO_PAGE,
  TOGGLE_MODAL
}