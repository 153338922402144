import * as firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/auth'
import { FirebaseResult } from 'AppModels/firebase'

/**
 * Firebase storage helper class
 */
export class FBStorage {
  /**
   * current firebase storage obj
   */
  storage: any

  constructor() {
    this.storage = firebase.storage()
  }

  /**
   * downloads file from storage async
   * @param pathToFile 
   * 
   * @example await new FBStorage().downloadAsync('/pdf/resume.pdf')
   * @returns {FirebaseResult} - result object
   */
  downloadAsync = async (pathToFile: string) => {
    const pathReference = this.storage.ref(pathToFile)
    const result = new FirebaseResult()
    try {
      // store the result in data property
      result.data = await pathReference.getDownloadURL()

    } catch (error) {
      result.success = false
      result.data = error
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
      case 'storage/object-not-found':
        result.message = 'File DNE'
        break
    
      case 'storage/unauthorized':
        result.message = 'Unauthorized access to storage'
        break
    
      case 'storage/canceled':
        result.message = 'Cancelled download'
        break
    
      case 'storage/unknown':
        result.message = 'Unknown error'
        break
      }
    }

    return result
  }
}