import { Text, Surface, Avatar } from 'react-native-paper'
import { View } from 'react-native'
import React from 'react'

export interface IIconCard {
  icon: string,
  title: string,
  info: string,
  iconSize?: number,
  style?: any,
  titleStyle?: any,
  infoStyle?: any,
  onPress?: any
}

export const IconCard = (props:IIconCard) => {
  const {
    icon, title, info, iconSize, style, titleStyle, infoStyle, onPress
  } = props

  let newSize = 50
  if (iconSize) {
    newSize = iconSize
  }
  return (
    <View
      style={{
        ...styles.container,
        ...style
      }}
    >

      <Surface
        style={{
          ...styles.card
        }}
      >
        <Avatar.Icon
          size={newSize}
          icon={icon}
          style={styles.avatarIcon}
        />
        <Text
          style={{
            paddingTop: 40,
            ...titleStyle
          }}
        >{title}</Text>
        <Text 
          style={{
            paddingTop: 20,
            ...infoStyle
          }}
          onPress={onPress}
        >
          {info}
        </Text>
      </Surface>
    
    </View>
  )
}

const styles = {
  container: {
    justifyContent: 'center',
    flexDirection: 'row',
    height: 100,
    width: 200,
    padding: 10
  },
  card: {
    alignSelf: 'center',
    alignItems: 'center',
    elevation: 4,
    width: '100%',
    height: '100%'
  },
  avatarIcon: {
    position: 'absolute',
    top: -30, 
    zIndex: 999, 
    shadowColor: '#000', 
    shadowOffset: {
      width: 1,
      height: 2
    }, 
    shadowOpacity: .6, 
    shadowRadius: 2
  }
}

