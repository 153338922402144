import { firebaseSetup } from 'AppFirebase'
import * as ScreenOrientation from 'expo-screen-orientation'
import { dispatch } from 'AppStore/index'
import * as ActionTypes from 'AppActions/types'
import * as Device from 'expo-device'
import { updateOrientation, updateDimensions } from './device'
import { Dimensions } from 'react-native'

/**
 * perform any setup here for the app
 */
export const appInit = async () => {

  firebaseSetup()
  Dimensions.addEventListener('change',updateDimensions)
  // setup listener to update orientation state
  ScreenOrientation.addOrientationChangeListener(updateOrientation)

  // initialize current device type and orientation
  dispatch({
    type: ActionTypes.INIT_APP,
    data: {
      deviceType: await Device.getDeviceTypeAsync(),
      orientation: await ScreenOrientation.getOrientationAsync(),
    }
  })
}

