import { DefaultTheme } from 'react-native-paper'

const colors = {
  ...DefaultTheme.colors,
  primary: '#ff0000',
  accent: '#cccfcd',
  text: 'black',
  secondaryColor: 'black',
}

const fonts = {
  ...DefaultTheme.fonts,
}

const defaultStyles = {
  container: {
    alignItems: 'center',
    flex:1,
    paddingVertical: 20
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 40
  },
  surface: {
    flex: 1,
    width: '95%',
    alignSelf: 'center',
    boxShadow: '1px 1px 5px grey'
  },
}

export const theme = {
  ...DefaultTheme,
  colors,
  fonts,
  defaultStyles
}
