import React from 'react'
import { View } from 'react-native'
import { Text, Title, IconButton, Button, Modal, Portal } from 'react-native-paper'
import { TouchableIcon } from 'AppComponents'
import { openLink, toggleModal } from 'AppActions'
import { useSelector } from 'react-redux'
import { IStarterInfoState } from 'AppReducers/starterInfoReducer'
import { AskCodeContainer } from './askCodeContainer'
import { IDimensionsState } from 'AppReducers'

export interface IButton {
  title: string,
  onPress: any
}

export interface IStarterInfoContainer {
  leftButton: IButton,
  rightButton: IButton
}

const SocialMediaComponent = () => {
  
  return (
    <View
      style={{
        flex:1,
        flexDirection: 'row'
      }}
    >
      <IconButton 
        icon={'linkedin'} 
        color='#0072b1' 
        size={40} 
        style={{
          alignSelf: 'center'
        }} 
        onPress={() => {openLink('https://bo.linkedin.com/company/rlga-technical-services-llc', true)}}
      />
      <IconButton 
        icon={'facebook-square'} 
        color='#3b5998' 
        size={40} 
        style={{
          alignSelf: 'center'
        }} 
        onPress={() => {openLink('https://www.facebook.com/specsandcodes/', true)}}
      />
      <IconButton 
        icon={'twitter-square'} 
        color='#00acee' 
        size={40} 
        style={{
          alignSelf: 'center'
        }} 
        onPress={() => {openLink('https://twitter.com/specsandcodes', true)}}
      />
    </View>
  )
}

export const StarterInfoContainer = (props:any) => {

  const {
    style, leftButton, rightButton
  } = props

  const state = useSelector((state:any) => state)
  const data: IStarterInfoState = state.starterInfoReducer
  const dimensions: IDimensionsState = state.dimensionsReducer

  return (
    <View
      style={{
        ...styles.container, 
        ...style,
        height: dimensions.shouldDisplayMobile ? null : 375
      }
      }
    >
      <Title style={styles.title}>About</Title>

      {/* Main Content */}
      <View style={styles.contentContainer}>

        <View style={styles.content}>
          <Text style={styles.rlgaTitle}>
            Ronald L. Geren, FCSI, AIA, CCS, CCCA, SCIP
          </Text>

          <Text style={styles.rlgaText}>
            {'Ron Geren has over 27 years of experience in the design and construction industry.  He\'s held several management and technical positions with public and private organizations in both design and owner-representation positions.'}
          </Text>

          <Text style={styles.rlgaText}>
            Ron Geren is a 1984 architecture graduate of the University of Arizona, and is a registered architect in the state of Arizona.
          </Text>
        </View>

        { // show profile image only on web
          dimensions.shouldDisplayMobile
            ? null
            : <View
              style={{
                flex:1
              }}
            >
              <TouchableIcon
                style={styles.image}
                imageStyle={{
                  flex:1
                }}
                image={require('../assets/Ron_Geren.jpg')} 
              />
            </View>
        }

      </View>

      <Portal>
        <Modal
          visible={data.modalOpen} 
          onDismiss={() => {toggleModal()}} 
          contentContainerStyle={styles.modal}
        >
          <View>
            <AskCodeContainer />
          </View>
        </Modal>
      </Portal>

      {/* secondary content */}
      <View
        style={{
          flex:1,
          flexDirection: dimensions.shouldDisplayMobile ? 'column' : 'row'
        }}
      >
        <View
          style={{
            flex:dimensions.shouldDisplayMobile ? null : 2,
            flexDirection: dimensions.shouldDisplayMobile ? 'column' : 'row', 
            justifyContent: 'center'
          }}
        >
          <Button
            style={styles.button}
            mode='contained'
            onPress = {leftButton.onPress}
          >
            {leftButton.title}
          </Button>
          <Button
            style={styles.button}
            mode='contained'
            onPress = {rightButton.onPress}
          >
            {rightButton.title}
          </Button>
        </View>
        <View
          style={{
            flex:1
          }}
        >
          <View
            style={{
              alignSelf: 'center'
            }}
          >
            <SocialMediaComponent />
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = {
  container: {
    flex:1,
    flexDirection: 'column',
    margin: 10,
    flexWrap: 'wrap'
  },
  title: {
    color: '#ff0000', 
    fontSize: 35
  },
  contentContainer: {
    flex:4, 
    flexDirection: 'row'
  },
  content: {
    flex:2, 
    flexDirection: 'row', 
    flexWrap: 'wrap',
  },
  rlgaTitle: {
    fontSize: 26,
    color: 'black',
    marginBottom: 8,
    marginTop: 10
  },
  rlgaText: {
    color: 'black',
    fontSize: 20,
    marginBottom: 8,
  },
  image: {
    flex:1,
    padding: 10
  },
  button: {
    flex:1,
    margin: 5,
    alignSelf: 'center'
  },
  modal: {
    width: '75%', 
    backgroundColor: 'white', 
    alignSelf: 'center', 
    borderRadius: 20, 
    padding: 15
  }
}