import React from 'react'
import { RouterSwitch, Route } from 'AppComponents'
import * as containers from 'AppContainers'
import { PageNotFoundContainer } from 'AppContainers/pageNotFound'
import { get } from 'lodash'

interface INavigation {
  navigationConfigs: Array<string>, // object of navigation configs
  defaultContainerName?: string //default container to show if the Container DNE
}

/**
 * builds the react-router component based on the passed in configs
 * @param {INavigation} navigation
 * 
 * @returns {Array} - array of route components
 */
const buildRoutes = (navigation: INavigation) => {

  const { navigationConfigs, defaultContainerName } = navigation

  if (!navigationConfigs)
    return null

  const RoutesArray = Object.entries(navigationConfigs).map(([ key, value ]) => {
    // find the Component from componentName 
    // if Component doesnt exist, use the defaultContainer name for an error page
    // if defaultContainer doesnt exist, use the PageNotFoundContainer
    const Component = containers[value]
      ? containers[value]
      : defaultContainerName && defaultContainerName in containers
        ? containers[defaultContainerName]
        : PageNotFoundContainer

    return(
      <Route 
        exact
        key={key} 
        path={key} 
        component={Component}
      />
    )
  })
  RoutesArray.push(<Route
    exact
    key={'*'}
    path={'*'}
    component={PageNotFoundContainer}
  />)
  return RoutesArray
}


/**
 * @summary Sets up the routes + containers to be used in the app or other containers
 * @param {INavigation} navigation
 * 
 * @returns {Component} - wrapper containing route components
 */
export const ContainerRoutes = React.memo((navigation: INavigation) => {
  
  const navigationConfigs = get(navigation, ['navigationConfigs'])
  if (!navigationConfigs)
    return null

  return (
    <RouterSwitch>
      {buildRoutes(navigation)}     
    </RouterSwitch>
  )
})