import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { StarterInfoContainer } from './starterInfoContainer'
import { theme } from 'AppTheme'
import { openLink } from 'AppActions'
import { Surface, List, Divider, withTheme } from 'react-native-paper'
import { Dropdown } from 'AppComponents'
import { DropDownItem } from 'AppModels'
import { FontAwesome } from '@expo/vector-icons'
import { FBStorage } from 'AppFirebase/fbStorage'
/**
 * returns list item components based on passed in title(s)
 * @param titles - list of titles
 * @returns {Component}
 */
const setupListItem = (titles:string[]) => {
  
  return titles.map(title => {
    return (
      <List.Item
        key={title}
        title={title}
        left={props => {
          return (
            <View style={styles.iconStyle}>
              <FontAwesome
                name={'circle'}
                size={22}
                color={'#f00'}
              />
            </View>
          )
        }}
        titleStyle={{
          fontSize:18
        }}
      />
    )
  })
}

const arrLicenses = [ 
  'Registered Architect (Arizona)', 
  'Certified Construction Specifier (CSI)', 
  'Certified Construction Contract Administrator (CSI)', 
  'Certified Building Plans Examiner (ICC)' 
]

const arrMemberships = [ 
  'Specifications Consultants in Independent Practice (SCIP)', 
  'International Code Council (ICC)', 
  'National Fire Protection Association (NFPA)' 
]

const arrCSI = [ 
  'Institute President-Elect:  Current', 
  'Insitute Vice President:  FY14 - FY15 term', 
  'Institute Director, Southwest Region:  FY12 - FY13 term', 
  'Southwest Region President:  FY11 term', 
  'Southwest Region Vice President:  FY09 - FY10 term', 
  'Southwest Region Programs Chair:  FY06 - FY08', 
  'Phoenix Chapter Academic Programs Committee Chair:  FY07 - FY11', 
  'Phoenix Chapter President:  FY08 term',
  'Phoenix Chapter President-Elect:  FY07 term', 
  'Phoenix Chapter 1st Vice President:  FY06 term', 
  'Phoenix Chapter 2nd Vice President:  FY05 term', 
  'Phoenix Chapter Programs Committee Chair:  FY03 - FY08' 
]

const arrMasterSpec = [ 
  'Chair:  2012', 
  'Vice Chair:  2011', 
  'Committee Member:  2008 - 2010', 
  'Alternate Member:  2007' 
]

/**
 * builds about surface, with intro information (StarterInfoContainer) and dropdown lists for licenses, etc. and memberships, etc.
 * @param props 
 */
export const AboutContainer = withTheme((props:any) => {

  const { colors } = props.theme
  const [ buttonUrls, setButtonUrls ] = useState({
    resumeUrl: '',
    cvUrl: ''
  })

  // used to perform async within a component
  // 1. calls download on firebase storage to get the document url
  // 2. sets them to the local state
  useEffect( () => {
    const getUrl = async () => {
      const resume = await new FBStorage().downloadAsync('/pdf/resume.pdf')
      console.log(resume)
      const cv = await new FBStorage().downloadAsync('/pdf/cv.pdf')
      setButtonUrls({
        resumeUrl: resume.success ? resume.data : 'https://drive.google.com/file/d/1Gwn98IGX5edTEfNcmUBnOCVY8qTdqLNX/view?usp=sharing',
        cvUrl: cv.success ? cv.data : 'https://drive.google.com/file/d/1z9LeouFQCbAg_kjqnuKoSe7sjZLGqJMp/view?usp=sharing'
      })
    }
    getUrl()
  }, [])

  console.log(buttonUrls)

  return (
    <View
      style={{
        ...theme.defaultStyles.container,
        marginTop: 20,
        marginBottom: 20
      }}
    >
      <Surface
        style={{
          ...theme.defaultStyles.surface,
          ...styles.surface
        }}
      >
        <View
          style={{
            flex: 1
          }}
        >
          <StarterInfoContainer 
            leftButton={{
              title: 'Resume',
              onPress: () => {openLink(buttonUrls.resumeUrl, true)}
            }}
            rightButton={{
              title: 'Curriculum Vitae',
              onPress: () => {openLink(buttonUrls.cvUrl, true)}
            }}
          />
        </View>
      </Surface>

      <Divider
        style={{
          backgroundColor: colors.accent,
          ...styles.divider
        }}
      />

      <Surface
        style={{
          ...theme.defaultStyles.surface,
          padding: 25,
          flex: null
        }}
      >
        <Dropdown
          grouped={true}
          items={[
            new DropDownItem({
              title:'Licenses, Certifications, and Accreditations',
              id:'1',
              titleStyle: styles.titleStyle,
              left:
                <View style={styles.iconStyle}>
                  <FontAwesome
                    name={'chevron-circle-right'}
                    size={25}
                    color={'#f00'}
                  />
                </View>
            }, {
              Component:
                <View>
                  {setupListItem(arrLicenses)}
                </View>
            }),
            new DropDownItem({
              title:'Memberships and Participation',
              id:'2',
              titleStyle: styles.titleStyle,
              left:
                <View style={styles.iconStyle}>
                  <FontAwesome
                    name={'chevron-circle-right'}
                    size={25}
                    color={'#f00'}
                  />
                </View>
            }, {
              Component:
                <View>
                  <Dropdown
                    grouped={true}
                    items={[
                      new DropDownItem({
                        title:'Construction Specifications Institute (CSI)',
                        id:'1',
                        titleStyle: styles.contentStyle,
                        left:
                          <View style={styles.iconStyle}>
                            <FontAwesome
                              name={'chevron-circle-right'}
                              size={22}
                              color={'#f00'}
                            />
                          </View>
                      }, {
                        Component:
                        <View>
                          {setupListItem(arrCSI)}
                        </View>
                      })
                    ]}
                  />
                  <Dropdown
                    grouped={true}
                    items={[
                      new DropDownItem({
                        title:'American Institute of Architects (AIA)',
                        id:'1',
                        titleStyle: styles.contentStyle,
                        left:
                          <View style={styles.iconStyle}>
                            <FontAwesome
                              name={'chevron-circle-right'}
                              size={22}
                              color={'#f00'}
                            />
                          </View>
                      }, {
                        Component:
                        <View>
                          <Dropdown 
                            grouped={true}
                            items={[
                              new DropDownItem({
                                title:'MasterSpec® Architectural Review Committee',
                                id:'2',
                                titleStyle: styles.contentStyle,
                                left:
                                  <View style={styles.iconStyle}>
                                    <FontAwesome
                                      name={'chevron-circle-right'}
                                      size={22}
                                      color={'#f00'}
                                    />
                                  </View>
                              }, {
                                Component:
                                <View>
                                  {setupListItem(arrMasterSpec)}
                                </View>
                              })
                            ]}
                          />
                        </View>
                      })
                    ]}
                  />
                  {setupListItem(arrMemberships)}
                </View>
            })
          ]}
        />
      </Surface>
    </View>
  )
})

const styles = {
  iconStyle: {
    justifyContent: 'center', 
    marginRight: 20
  },
  surface: {
    maxHeight: 400,
    borderColor: 'black',
    paddingLeft: 25,
    paddingTop: 25,
    paddingBottom: 25
  },
  divider: {
    margin: 15,
    width: '97%',
    height: 1
  },
  titleStyle: {
    fontSize: 20
  },
  contentStyle: {
    fontSize: 18
  }
}