import * as Device from 'expo-device'
import * as ActionTypes from 'AppActions/types'
import { Orientation } from 'expo-screen-orientation'

export interface IAppState {
  initialized: boolean,
  deviceType: Device.DeviceType
  orientation: Orientation,
  sideMenuOpen: boolean,
  currentPageName: string,
}

const initialState: IAppState = {
  initialized: false,
  deviceType: Device.DeviceType.UNKNOWN,
  orientation: Orientation.UNKNOWN,
  sideMenuOpen: false,
  currentPageName: 'Home',
}

export const appReducer = (state = initialState, action: any) => {

  switch (action.type) {

  case ActionTypes.INIT_APP:
    console.log('INIT APP REDUCER CALLED')
    return {
      ...state,
      initialized: true,
      deviceType: action.data.deviceType,
      orientation: action.data.orientation,
    }

  case ActionTypes.NAVIGATE_TO_PAGE:

    return {
      ...state,
      currentPageName: action.data.name ? action.data.name : state.currentPageName
    }

  case ActionTypes.UPDATE_ORIENTATION:
    console.log('orientation updating...')
    return {
      ...state,
      orientation: action.data.orientation
    }

  case ActionTypes.TOGGLE_SIDE_MENU:
    return {
      ...state,
      sideMenuOpen: !state.sideMenuOpen
    }

  default:
    return state
  }
}