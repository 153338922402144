import { View, Animated, TouchableOpacity } from 'react-native'
import { Modal, Portal } from 'react-native-paper'
import React from 'react'

// TODO: move this into its own function to be called anywhere
const slideInVal = new Animated.Value(-300)

const interpolateIn = () => {
  Animated.timing(slideInVal, {
    toValue: 0,
    duration: 500,
    // useNativeDriver: true
  }).start()
}

const interpolateOut = () => {
  Animated.timing(slideInVal, {
    toValue: -300,
    duration: 500,
    // useNativeDriver: true
  }).start()
}

interface ISideMenu {
  visible: boolean,
  onDismiss?: any,
  onOutsidePress?: any,
  children?: any
}

export const SideMenu = (props:ISideMenu) => {
  const {
    visible, onDismiss, onOutsidePress, children
  } = props

  // animate in
  if (visible)
    interpolateIn()
  
  return (
    <Portal>
      <Modal 
        visible={visible}
        dismissable={false}
        onDismiss={onDismiss} 
        contentContainerStyle={{
          flex:1, 
          transform: [{
            translateX: slideInVal,
          }],
        }}
      >
        {/* allows action outside of the side menu */}
        <TouchableOpacity 
          style={{
            backgroundColor: 'transparent',
            flex:1 
          }}
          onPress={() => {interpolateOut(); onOutsidePress()}}
          activeOpacity={1}
        />
        <View
          style={{
            height:'100%',
            width: '70%',
            backgroundColor: 'white',
            position: 'absolute'
          }}
        >
          {children}
        </View>
      </Modal>
    </Portal>
  )
}
