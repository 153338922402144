import { networkRequest } from 'AppNetwork/networkRequest'
import { NetworkRequestModel } from 'AppModels'
import { IAskCodeData } from 'AppReducers'
import { HttpMethods } from 'AppConstants'
import environment from 'AppUtils/environment'

/**
 * sends email
 * @param data 
 * 
 * @returns {NetworkResponseModel}
 */
export const sendEmail = async (data: IAskCodeData) => {
  
  const controller = '/email'
  const request = new NetworkRequestModel(`${environment.apiUrl}${controller}`, HttpMethods.POST, data)
  return await networkRequest(request)

}