import Swiper from 'react-native-web-swiper'
import React from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-paper'

export interface ICarousel {
  /** array of data to be displayed */
  data: ICarouselItem[],
  /**
   * Custom component for the next button
   */
  NextComponent?: any,
  /**
   * Custom component for the prev button
   */
  PrevComponent?: any,
  style?:any

}

export interface ICarouselItem {
  displayLabel?: string,
  Component?: any
}

/**
 * Builds the component for carousel item
 * @param props
 */
const renderData = (props:ICarousel) => {

  const data = props.data

  return data.map((item:ICarouselItem, index:number) => {
    // render the custom component if exists, otherwise display the 'displayLabel' as text
    return item.Component 
      ? (
        <View key={index}>
          {item.Component}
        </View>
      )
      : item.displayLabel
        ? <Text
          key={index}
          style={{
            color: 'black'
          }}
        > {item.displayLabel} </Text>
        : null
  })
}


/**
 * Builds a carousel given a custom data set and component
 * ref: https://github.com/march213/react-native-web-swiper
 * @param props 
 */
export const Carousel = (props:ICarousel) => {

  const { style } = props
  return (
    <View
      style={{
        ...defaultStyle.container,
        ...style
      }}
    >
      <Swiper
        //containerStyle={{backgroundColor: 'blue'}}
        loop
        timeout={3}
        innerContainerStyle={{
          flex:1
        }}
        //slideWrapperStyle={{height: '100%', width: '100%'}}
        swipeAreaStyle={{
          flex:1
        }}
        from={0} // initial slide index
        controlsProps={{
          dotsTouchable: true,
          prevPos: 'left',
          nextPos: 'right',
          nextTitle: '>',
          nextTitleStyle: {
            color: 'red',
            fontSize: 24,
            fontWeight: '500' 
          },
          prevTitle: '<',
          prevTitleStyle: {
            color: 'red',
            fontSize: 24,
            fontWeight: '500' 
          },
          NextComponent: props.NextComponent,
          PrevComponent: props.PrevComponent
        }}
      >
        
        {renderData(props)}

      </Swiper>
    </View>
  )

}

const defaultStyle = {
  container: {
    flex: 1
  }
}