import { Subheading, IconButton, Text } from 'react-native-paper'
import { View } from 'react-native'
import React from 'react'

export interface IImageLabelText {
  title?: string,
  info?: string,
  icon?: string,
  iconSize?: number,
  titleStyle?: any,
  infoStyle?: any, 
}

/**
 * Creates an icon with a header/text component
 * |    | Header |
 * |Icon| text   |
 * |    |        | 
 */
export const ImageLabelText = (props:IImageLabelText) => {
    
  const {
    title, icon, info, infoStyle, titleStyle, iconSize
  } = props
  
  return (
    <View style={styles.container}>
    
      {/* left */}
      <IconButton
        icon={icon}
        color='black'
        style={{
          alignSelf: 'center'
        }}
        size={iconSize || 30}
      />

      {/* right */}
      <View
        style={{
          flexDirection: 'column',
          justifyContent:'center' 
        }}
      >
        <Subheading
          style={{
            ...styles.title,
            ...titleStyle
          }}
        >
          {title} 
        </Subheading>
        <Text  
          style={{
            ...styles.text,
            ...infoStyle
          }}
        >
          {info}
        </Text>

      </View>
    </View>
  )
}

const styles = {
  container: {
    flexDirection: 'row', 
    flexWrap: 'wrap',
    padding: 8
  },
  title: {
    color: '#ff0000'
  },
  text: {
    fontSize: 16,
  }
}