import Constants from 'expo-constants'
import { RELEASE_CHANNEL } from 'react-native-dotenv'

export const BASE_API_URL = 'https://us-central1-specsandcodes-4dfb7.cloudfunctions.net/api'

const ENV = {
  dev: {
    apiUrl: 'http://localhost:9000/specsandcodes-4dfb7/us-central1/api'
  },
  prod: {
    apiUrl: BASE_API_URL
  }
}

/**
 * returns the env obj based on passed in env
 * @param env - dev/prod
 */
const getEnvVars = (env = 'dev') => {
  if (env.indexOf('dev') !== -1) 
    return ENV.dev
  if (env.indexOf('prod') !== -1) 
    return ENV.prod

  return ENV.prod
}

export default getEnvVars(Constants.manifest.releaseChannel || RELEASE_CHANNEL)