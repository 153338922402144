import React from 'react'
import { View } from 'react-native'
import { Button, Surface, Subheading, withTheme, Divider } from 'react-native-paper'
import { TouchableIcon,
  BulletPointText, 
  IBulletPointText,
  Carousel,
  ICarouselItem } from 'AppComponents'
import { ContactContainer } from '../contactContainer'
import { StarterInfoContainer } from '../starterInfoContainer'
import { navigateTo, toggleModal } from 'AppActions'
import { IDimensionsState } from 'AppReducers'
import { useSelector } from 'react-redux'


const textItems:IBulletPointText[] = [
  {
    title: 'Design Team: ' ,
    text: 'Architects, engineers, interior designers, and specialty consultants.'
  },
  {
    title: 'Owner Team: ',
    text: 'Facility managers, project managers, and space planners.'
  },
  {
    title: 'Contractor Team: ',
    text: 'Construction managers, contractors, and design-builders.'
  },
  {
    title: 'Supplier Team: ',
    text: 'Manufacturers, distributors, and product representatives.'
  }
]

const setupBulletPointTexts = () => {

  return textItems.map((item, index) => {
    return (
      <BulletPointText 
        key={index} 
        title={item.title} 
        text={item.text}
        textStyle={{
          fontSize: 14
        }}
        titleStyle={{
          fontSize: 16,
          fontWeight: 'bold'
        }}
      />
    )
  })
}

const SummaryComponent = (props: any) => {

  return (
    <View
      style={{
        flex:2
      }}
    >

      <Subheading
        style={{
          padding: 8,
          color: props.textColor,
          fontWeight: 'bold'
        }}
      >
        {'RLGA Technical Services '} 
        <Subheading
          style={{
            color: props.textColor
          }}
        >
          provides construction specification and building code consulting services for the entire Project Team
        </Subheading>
      </Subheading>
      {setupBulletPointTexts()}

      <View
        style={{
          flexDirection: 'row',
          justifyContent:'center'
        }}
      >
        <Button
          style={styles.button}
          mode='contained'
          onPress={() => navigateTo('/services')}
        >
          LEARN MORE
        </Button>

        <Button
          style={styles.button}
          mode='contained'
          onPress={() => {
            navigateTo('/store')
          }}
        >
          STORE
        </Button>

      </View>
      
    </View>
  )
}


const TestRenderImage = (props:any) => {
  const { imageName } = props
  let image
  switch (imageName) {
  case 'Book.jpg': 
    image = require('AppAssets/Book.jpg')
    break
  case 'VOSB_symbol.png': 
    image = require('AppAssets/VOSB_symbol.png')
    break
  }
  return (
    <TouchableIcon 
      image={image} 
      style={styles.book}
      // onPress={() => {}}
      imageStyle={{
        flex:1
      }}
    />
  )
}

const testData: ICarouselItem[] = [
  {
    Component: <TestRenderImage imageName={'Book.jpg'}/>
  },
  {
    Component: <TestRenderImage imageName={'VOSB_symbol.png'}/>
  }
]


const BottomSection = withTheme((props:any) => {

  const { theme, borderColor } = props

  return (
    <Surface
      style={{
        ...theme.defaultStyles.surface,
        borderColor: borderColor,
        padding: 20
      }}
    >
      <View
        style={{
          flex:1,
          flexDirection: 'column'
        }}
      >
        <View
          style={{
            flex:1,
            flexDirection: 'row',
            marginTop: 20,
            marginBottom: 20
          }}
        >
          <StarterInfoContainer 
            leftButton={{
              title: 'Learn More',
              onPress: () => {navigateTo('/about')}
            }}
            rightButton={{
              title: 'Ask A Code Question',
              onPress: () => {toggleModal()}
            }}
          />
        </View>
        <Divider
          style={{
            backgroundColor: theme.colors.accent,
            margin: 15,
            marginTop: 30
          }}
        />
        <ContactContainer
          style={{
            justifyContent: 'center',
            marginTop: 40
          }}
        />
      </View>
    </Surface>
  )
})


export const HomeContainer = withTheme((props:any) => {

  const { colors, defaultStyles } = props.theme
  const state = useSelector((state:any) => state)
  const dimensions: IDimensionsState = state.dimensionsReducer
  
  return (
    <View
      style={{
        ...defaultStyles.container
      }}
    >
      <View 
        style={styles.scrollView}
      >

        {/* Description & carousel */}
        <Surface
          style={{
            ...defaultStyles.surface, 
            borderColor: colors.accent,
            ...dimensions.shouldDisplayMobile ? styles.surface.mobile : styles.surface.web
          }}
        >
          <SummaryComponent textColor={colors.secondaryColor} />
          
          <Carousel
            style={styles.carousel}
            data={testData}
          />

        </Surface>

        <Divider
          style={{
            backgroundColor: colors.accent,
            margin: 15
          }}
        />

        {/* bottom half */}
        <BottomSection borderColor={colors.accent}/>

      </View>  
    </View>
  )
})

const styles = {
  scrollView: {
    paddingVertical: 20
  },
  carousel: {
    flex:1,
    height: 320
  },
  surface: {
    web: {
      flexDirection: 'row',
      padding: 15,
    },
    mobile: {
      flexDirection: 'column',
    },
  },
  book: {
    height: 300,
    padding: 5
  },
  button: {
    width: '40%',
    margin: 5
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22
  }
}
