import { Subheading, IconButton } from 'react-native-paper'
import { View } from 'react-native'
import React from 'react'

export interface IBulletPointText {
  title: string,
  text: string,
  textStyle?: any,
  titleStyle?: any,
  hideIcon?: boolean,
  style?: any
}

/**
 * Combines title and text into one nice looking component with icon
 * @param props
 * @property {string} title - title displayed first
 * @property {string} text - follows the title text
 * 
 */
export const BulletPointText = (props:IBulletPointText) => {

  const {
    title, text, textStyle, titleStyle, hideIcon, style
  } = props

  return (
    <View
      style={{
        flex:1,
        flexDirection: 'row',
        ...style
      }}
    >

      {hideIcon ? null : <IconButton
        icon='angle-right'
        color='black'
      />}
      
      <Subheading
        style={{
          color: 'black',
          alignSelf: 'center',
          ...titleStyle
        }}
      >
        {title} 
      </Subheading>
      <Subheading
        style={{
          color: 'black',
          alignSelf: 'center',
          ...textStyle
        }}
      >
        {text}
      </Subheading>
    </View>
  )
}