import React from 'react'
import { NavItem } from 'AppModels'
import { useSelector } from 'react-redux'
import { IAppState } from 'AppReducers/appReducer'
import { appInit } from 'AppActions'
import { WebContainer } from './web'
import { MobileContainer } from './mobile'
import { RenderDeviceComponent } from 'AppComponents'
import { ContainerRoutes } from 'AppNavigation/containerRoutes'
import { routes } from 'AppConfig'
import { View, ImageBackground } from 'react-native'
import { IDimensionsState } from 'AppReducers'

const headerItems = [
  new NavItem('Store', '/store', 'shopping-bag'),
  new NavItem('Services', '/services', 'building'),
  new NavItem('About', '/about'),
  new NavItem('Blogs', '/blogs'),
  new NavItem('Articles', '/articles')
]

/**
 * sets up the app header and dynamic contents
 */
export const RootContainer:React.FC = React.memo(() => {

  // get app state
  const state = useSelector((state:any) => state)
  const data: IAppState = state.appReducer
  const dimensions: IDimensionsState = state.dimensionsReducer

  /*
    0 - Unknown
    1 - phone
    2 - tablet
    3 - desktop
    4 - tv
  */
  //console.log(`Device Type: ${data.deviceType}`)

  /*
    1,2 - portrait
    3,4 - landscape
  */
  //console.log(`Orientation: ${data.orientation}`)
  // initialize app stuff first
  if (!data.initialized) {
    appInit()
    return null
  } 


  let minWidth = 1100
  let alignSelf = 'center'
  if (dimensions.shouldDisplayMobile) {
    minWidth = 50
    alignSelf = null
  }

  return (
    <View style={styles.container}>

      <RenderDeviceComponent 
        WebContainer={<WebContainer headerItems={headerItems} />}
        MobileContainer={ <MobileContainer headerItems={headerItems} />}
      />
      <ImageBackground
        style={{
          flex:1
        }}
        imageStyle={{
          opacity:0.4
        }}
        source={require('../../assets/background_2.jpg')}
      >

        {/* setup routing for navigation */}
        <View
          style={{
            ...styles.content,
            minWidth, 
            alignSelf
          }
          }
        >
          <ContainerRoutes navigationConfigs={routes}/> 
        </View>
      </ImageBackground>
    </View>

  )
})

const styles = {
  container: {
    flex: 1,
    backgroundColor: '#fff'
  }, 
  content: {
    marginTop: 30, 
    flex: 1, 
    maxWidth: 1500
  }
}