import { useSelector } from 'react-redux'
import { IDimensionsState } from 'AppReducers'

/**
 * Renders web or mobile component based on the current app state
 */
export const RenderDeviceComponent = (props: any) => {
  const { WebContainer, MobileContainer } = props

  // get app state
  const state = useSelector((state:any) => state)
  const dimensions: IDimensionsState = state.dimensionsReducer

  // if mobilecontainer not passed in, use the web container
  return dimensions.shouldDisplayMobile
    ? MobileContainer ? MobileContainer : WebContainer
    : WebContainer
}