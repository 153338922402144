import { dispatch } from 'AppStore/index'
import * as ActionTypes from 'AppActions/types'
import { IAskCodeData } from 'AppReducers'
import { AppAlert } from 'AppUtils'
import { sendEmail } from './email'
import { toggleModal } from './navigation'
import { AsyncStatus } from 'AppConstants'

/**
 * Validates the data and send an email
 * @param {IAskCodeData} data
 */
export const askCodeformSubmit = async(data: IAskCodeData) => {

  const errorItem = []
  if (!data.email) {
    errorItem.push('email')
  }

  if (!data.name) {
    errorItem.push('name')
  }

  if (!data.question) {
    errorItem.push('question')
  }

  if(!data.isValidEmail) {
    errorItem.push('valid email')
  }

  if (errorItem.length > 0) {
    AppAlert(`Error: ${errorItem.join(', ')} is required`)
  }
  else {
    // dispatch status each step of the way during async
    dispatch({
      type: ActionTypes.ASK_CODE_FORM_SUBMIT,
      data: {
        asyncStatus: AsyncStatus.Pending
      }
    })

    const result = await sendEmail(data)
    if (result.success) {
      dispatch({
        type: ActionTypes.ASK_CODE_CLEAR_INPUT,
      })
      toggleModal()
    }
    else {
      dispatch({
        type: ActionTypes.ASK_CODE_FORM_SUBMIT,
        data: {
          asyncStatus: AsyncStatus.Failed
        }
      })
      AppAlert('Error: network error. Please try again later')
    }

  }
    
}

export const askCodeInputChange = (data:IAskCodeData) => {
  dispatch({
    type: ActionTypes.ASK_CODE_ON_INPUT_CHANGE,
    data
  })
}