import { HttpMethods } from 'AppConstants'


export class NetworkRequestModel {
  method: string
  url?: string
  headers: any
  responseType: string
  params: any

  constructor(url: string, method?: string, params?: any, headers?: any, responseType?: string) {
    
    this.method = method || HttpMethods.GET
    this.url = url
    this.headers = headers || {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    this.responseType = responseType || 'json'
    this.params = params
  }
}
