import { View } from 'react-native'
import React from 'react'
import { List } from 'react-native-paper'
import { DropDownItem } from 'AppModels/dropDownItem'

export interface IDropdown {
  grouped?: boolean,
  style?: any,
  items: DropDownItem[]
}

/**
 * Groups the component
 * this allows for only 1 list to be expanded at a time
 * @param Component 
 */
const GroupHoc = (Component:any) => {
  return (
    <List.AccordionGroup>
      {Component}
    </List.AccordionGroup>
  )
}

/**
 * Loops through the item list and creates the accordion component
 * @param props
 */
const Accordion = (props:IDropdown) => {
  const { items } = props

  return (
    <>
      {
        items.map((item:DropDownItem, index) => {
          console.log(item.parent.style)
          return (
            <List.Accordion
              key={index}
              title={item.parent.title}
              id={item.parent.id} 
              style={item.parent.style}
              titleStyle={item.parent.titleStyle}
              left={() => item.parent.left}
            >
              {item.child.Component}
            </List.Accordion>
          )
        })
      }
    </>
  ) 
}

/**
 * Dropdown item
 * usage:
 *    <Dropdown
 *         grouped={true}
 *         items={dropDownItems}
 *         style={customStyles}
 *      />
 * @param props 
 */
export const Dropdown = (props:IDropdown) => {

  const {
    grouped, items, style 
  } = props
  
  return (
    <View>
      {(
        grouped
          ? GroupHoc(<Accordion
            items={items}
            style={style}
          />)
          : <Accordion
            items={items}
            style={style}
          />
      )}
    </View>
  )
}