
import axios from 'axios'
import { NetworkRequestModel, NetworkResponseModel } from 'AppModels'
import { HttpMethods } from 'AppConstants'

const networkResponseModel = new NetworkResponseModel()

/**
 * Builds the axios request model
 * @param {NetworkRequestModel} request - request model
 *
 * @returns {AxiosRequestConfig}
 */
const buildRequest = (request:NetworkRequestModel) => {

  const { params, ...requestOpt } = request

  // if request is anything other than GET, update the data property instead
  const dataTypeKey = request.method === HttpMethods.GET ? 'params' : 'data'
  
  
  return {
    ...requestOpt,
    [dataTypeKey]: params,
    // headers: {
    //   ...request.headers,
    //   ...requestOpt.headers,
    // },
  }
}

/**
 * Makes a network API request based on the passed in params
 * @param {NetworkRequestModel} request - request model
 * @param {Obj} [request.params] - querystring obj || body obj
 * @returns {networkResponse}
 */
export const networkRequest = async (request: NetworkRequestModel) => {
  try {
    // Built the request
    const builtRequest = buildRequest(request)

    // Log the request when in DEV environment
    //__DEV__ && logData(`Network Request:\n`, builtRequest)

    // builds request and pull out the data and status property
    const { data, status } = await axios(builtRequest)

    return {
      ...networkResponseModel,
      success: true,
      data: data,
      statusCode: status,
    }
  }
  catch ({
    response, message, stack 
  }) {
    //logData('ERROR in makeRequest', message, stack, 'error')

    return {
      ...networkResponseModel,
      success: false,
      data: response ? response.data : null,
      statusCode: response ? response.status : null,
      errorMessage: message,
    }
  }
}
