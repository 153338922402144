import React from 'react'
import { Image, TouchableOpacity } from 'react-native'

interface ITouchableIconProps {
  style?: any,
  imageStyle?: any,
  onPress?: () => void,
  image?: any
}

export const TouchableIcon = (props:ITouchableIconProps) => {

  const {
    style,
    onPress,
    image,
    imageStyle
  } = props

  return (
    <TouchableOpacity
      style={style}
      onPress={onPress}
      activeOpacity={0.9}
    >
      <Image
        style={imageStyle ? imageStyle : {
          flex:1
        }}
        source={image}
        resizeMode="contain"
      />
    </TouchableOpacity>
  )
}