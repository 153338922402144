import React from 'react'
import { View } from 'react-native'
import { StaticIFrame } from 'AppComponents'

export const StoreContainer = (props:any) => {
  return (
    <View
      style={{
        flex:1,
        overflow: 'hidden',
        marginBottom: -760,
        marginTop: 50
      }}
    > 
      <StaticIFrame 
        height={2530}
        src={'https://www.specsandcodes.com/index.html#page_content'}
        style={{
          top: -760
        }}
      />
    </View>
  )
}