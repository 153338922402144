export interface IDropDownParent {
  title: string,
  id: string,
  style?: object,
  titleStyle?: object
  left?: any
}

export interface IDropDownChild {
  Component: any
}

/**
 * Used to create a dropdown item object
 * example usage:
 *    new DropDownItem({title: 'Title 1', id: '1'}, {Component: <Text>Hello</Text>})
 */
export class DropDownItem {
  parent: IDropDownParent
  child: IDropDownChild

  constructor(parent: IDropDownParent, child: IDropDownChild) {
    this.parent = parent
    this.child = child
  }
}
