import React from 'react'
import { View, FlatList, TouchableOpacity } from 'react-native'
import { Appbar, withTheme, Divider } from 'react-native-paper'
import { IRootContainer } from './IRootContainer'
import { toggleSideMenu, navigateTo } from 'AppActions'
import { useSelector } from 'react-redux'
import { IAppState } from 'AppReducers/appReducer'
import { SideMenu, TouchableIcon, ImageLabelText } from 'AppComponents'
import { NavItem } from 'AppModels'

const AppHeader = withTheme((props: any) => {
  const { title } = props

  return (
    <View>
      <Appbar.Header
        style={{
          height: 80,
          elevation: 25
        }}
      >

        <Appbar.Action 
          icon="bars" 
          color={'white'} 
          onPress={() => {toggleSideMenu()}} 
        />

        <Appbar.Content
          title={title}
        />
      </Appbar.Header>
    </View>
    
  )

})

/**
 * Setup the side menu header
 * @param props 
 */
const SideMenuHeader = (props:any) => {

  return (
    <TouchableIcon 
      image={require('AppAssets/rlgalogo_xlarge.png')} 
      style={styles.logo}
      imageStyle={styles.logo}
      onPress={() => {navigateTo(new NavItem('Home', '/')); toggleSideMenu()}}
    />
  )
}

/**
 * Renders the side menu item list
 * @param props 
 * @property {NavItem} item
 */
const RenderItem = (props :any) => {
  const { name, icon }: NavItem = props.item

  return (
    // navigates to a route and closes side menu
    <TouchableOpacity 
      style={{
        flex:1
      }}
      onPress={() => {
        toggleSideMenu()
        navigateTo(props.item)
      }}
    >
      <ImageLabelText 
        titleStyle={{
          color: 'black',
          fontSize: 20
        }}
        icon={icon}
        title={name}
      />
    </TouchableOpacity>

  )
}

const SideMenuContent = (props:any) => {
  const { items } = props

  return (
    <FlatList
      data={items}
      renderItem={RenderItem}
      keyExtractor={item => item.routePath}
    />

  )
}
/**
 * sets up the view layout for mobile device
 */
export const MobileContainer:React.FC<IRootContainer> = withTheme((props: any) => {

  const { headerItems, theme } = props

  // get app state
  const state = useSelector((state:any) => state)
  const data: IAppState = state.appReducer

  return (
    <View>

      <SideMenu 
        visible={data.sideMenuOpen}
        onDismiss={() => {toggleSideMenu()}}
        onOutsidePress={() => {toggleSideMenu()}}
      >
        <SideMenuHeader {...props} />
        <Divider
          style={{
            backgroundColor: theme.colors.accent,
            margin: 15
          }}
        />
        <SideMenuContent items={headerItems} />
      </SideMenu>
 
      <AppHeader title={data.currentPageName}/>

    </View>
  )
})

const styles = {
  logo: {
    width: 250,
    height: 150,
    alignSelf: 'center',
    marginTop: 10,
    //boxShadow: '1px 1px 5px grey'
  },
}