import { dispatch } from 'AppStore/index'
import * as ActionTypes from 'AppActions/types'

export const updateDimensions = (event:any) => {
  const { width, height } = event.window

  dispatch({
    type: ActionTypes.UPDATE_DIMENSIONS,
    data: {
      height,
      width
    }
  })
}