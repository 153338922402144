import * as ActionTypes from 'AppActions/types'
import { Dimensions } from 'react-native'

export interface IDimensionsState {
  width: number,
  height: number,
  shouldDisplayMobile: boolean
}
const initialState: IDimensionsState = {
  width: Math.round(Dimensions.get('window').width) || 0,
  height: Math.round(Dimensions.get('window').height) || 0,
  get shouldDisplayMobile() {
    return this.width <= 500
  }
}

export const dimensionsReducer = (state = initialState, action: any) => {

  switch (action.type) {

  case ActionTypes.UPDATE_DIMENSIONS:
    return {
      ...state,
      width: action.data.width,
      height: action.data.height,
      shouldDisplayMobile: action.data.width <= 500 ? true : false
    }

  default:
    return state
  }
}