import React from 'react'
import { View } from 'react-native'
import { Title, withTheme, Button, IconButton, ActivityIndicator } from 'react-native-paper'
import { askCodeInputChange, askCodeformSubmit } from 'AppActions/askCodeFormAction'
import { Picker } from 'AppComponents'
import { useSelector } from 'react-redux'
import { JOB_TITLE, AsyncStatus } from 'AppConstants'
import { Validate } from 'AppUtils'
import { TextInput } from 'AppComponents/input'
import { toggleModal } from 'AppActions' 
import { IAskCodeData } from 'AppReducers'

export const AskCodeContainer = withTheme((props) => {

  const { colors } = props.theme
  const state = useSelector((state:any) => state)
  const data:IAskCodeData = state.askCodeReducer
  const jobArray = Object.values(JOB_TITLE)
  const isPendingSubmission = data.asyncStatus == AsyncStatus.Pending

  return (
    <View style={styles.container}>
      <View
        style={{
          flex:1,
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            flex:1
          }}
        >
          <Title
            style={{
              color: colors.primary,
              fontSize: 30
            }}
          >Ask A Code Question</Title>
        </View>
        <View
          style={{
            flex:1,
            flexDirection: 'row-reverse'
          }}
        >
          <IconButton 
            icon='times'
            color='black'
            size={20}
            onPress={() => {
              toggleModal()
            }} 
          />
        </View>
      </View>
      <TextInput
        disabled={isPendingSubmission}
        style={styles.textInput}
        label='Name*'
        value={data.name}
        theme={{
          colors: {
            text: 'black'
          }
        }}
        onChangeText={(text:string) => askCodeInputChange({
          name: text
        })}
        validationText={'Name is required'}
        showValidationMessage={data.submitted && !data.name}
      />

      <TextInput
        disabled={isPendingSubmission}
        style={styles.textInput}
        label={'Email*'}
        value={data.email}
        theme={{
          colors: {
            text: 'black'
          }
        }}
        onChangeText={(text:string) => askCodeInputChange({
          email: text,
          isValidEmail: Validate.email(text)
        })}
        validationText={'Please enter a valid email'}
        showValidationMessage={data.submitted && !Validate.email(data.email)}
      />

      <Picker 
        title={'Job Title'}
        selectedValue={data.jobTitle}
        data={jobArray}
        onValueChange={(itemValue:any, itemIndex:number) => askCodeInputChange({
          jobTitle: itemValue
        })}
      />   

      <TextInput
        disabled={isPendingSubmission}
        style={styles.textInput}
        label='City/County/Authority'
        value={data.city}
        theme={{
          colors: {
            text: 'black'
          }
        }}
        onChangeText={(text:string) => askCodeInputChange({
          city: text
        })}
      />

      <TextInput
        disabled={isPendingSubmission}
        style={styles.textInput}
        label='State/Possession'
        value={data.state}
        theme={{
          colors: {
            text: 'black'
          }
        }}
        onChangeText={(text:string) => askCodeInputChange({
          state: text
        })}
      />

      <TextInput
        disabled={isPendingSubmission}
        style={styles.textInput}
        label='Code Title'
        value={data.codeTitle}
        theme={{
          colors: {
            text: 'black'
          }
        }}
        onChangeText={(text:string) => askCodeInputChange({
          codeTitle: text
        })}
      />

      <TextInput
        disabled={isPendingSubmission}
        style={styles.textInput}
        label='Code Edition/Year'
        value={data.codeYear}
        theme={{
          colors: {
            text: 'black'
          }
        }}
        onChangeText={(text:string) => askCodeInputChange({
          codeYear: text
        })}
      />

      <TextInput
        disabled={isPendingSubmission}
        style={styles.textInput}
        label='Code Section(s)'
        value={data.codeSection}
        theme={{
          colors: {
            text: 'black'
          }
        }}
        onChangeText={(text:string) => askCodeInputChange({
          codeSection: text
        })}
      />

      <TextInput
        disabled={isPendingSubmission}
        multiline={true}
        numberOfLines={10}
        style={styles.textInput}
        label='Question*'
        value={data.question}
        theme={{
          colors: {
            text: 'black'
          }
        }}
        onChangeText={(text:string) => askCodeInputChange({
          question: text
        })}
        validationText={'Question is required'}
        showValidationMessage={data.submitted && !data.question.trim()}
      />

      { // display loading indicator or submit button
        isPendingSubmission 
          ? <ActivityIndicator animating={true} />
          : <Button
            mode='contained'
            onPress={() => askCodeformSubmit(data)}
          >
              SUBMIT
          </Button>
      }
      

      
    </View>
  )
})

const styles = {
  container: {
    margin: 10,
    flex:1
  },
  textInput: {
    margin: 5,
    flex: 1,
  }
}