import React from 'react'
import { Provider as PaperProvider, Portal } from 'react-native-paper'
import {FontAwesome5} from '@expo/vector-icons'
import { getHistory } from 'AppNavigation'
import { Router } from 'AppComponents'
import { RootContainer } from 'AppContainers'
import { Provider } from 'react-redux'
import { getStore } from 'AppStore/index'
import { theme } from 'AppTheme'



/**
 * Entry point
 * @returns {React.FC} - react functional component
 */
const App:React.FC = () => {

  return (
    <Router history={getHistory()}>

      <PaperProvider 
        settings={{
          icon: props => <FontAwesome5 {...props} />,
        }}
        theme={theme}
      >
        <Provider store={getStore()}>
          <Portal.Host>
            <RootContainer/>
          </Portal.Host>
        </Provider>
      </PaperProvider>

    </Router>
    
  )
}

export default App