
import { openLink, navigateTo } from 'AppActions'

export const BlogsContainer = (props:any) => {

  openLink('https://specsandcodes.typepad.com/specsandcodes/', true)
  navigateTo('/')
  return (
    null
  )
}