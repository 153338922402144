
export class NavItem {
  name: string
  routePath: string
  icon?: string

  constructor(name: string, routePath: string, icon?: string) {
    this.name = name
    this.routePath = routePath
    this.icon = icon
  }
}