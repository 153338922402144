import React from 'react'
import { View } from 'react-native'
import { StaticIFrame } from 'AppComponents'

export const ServicesContainer = (props:any) => {
  return (
    <View
      style={{
        flex:1
      }}
    > 
      <StaticIFrame 
        height={2550}
        src={'https://specsandcodes.com/services/default.html#page_content'}
      />
    </View>
  )
}