import React from 'react'
import { View } from 'react-native'
import { Appbar, Button, withTheme } from 'react-native-paper'
import { navigateTo } from 'AppActions/navigation/navigateTo'
import { NavItem } from 'AppModels'
import { TouchableIcon } from 'AppComponents'
import { IRootContainer } from './IRootContainer'


/**
 * Goes through the HeaderItems and turns them into a list of buttons
 * @returns {React.Component}
 */
const setupHeaderItems = (items: [NavItem], color?:string) => {
  
  return items.map((item, index) => {
    return (
      <Button
        key={index}
        compact={true} 
        color={color}
        labelStyle={{
          fontSize: 20
        }}
        contentStyle={{
          height: 100
        }}
        onPress={() => {navigateTo(`${item.routePath}`)}}
      >
        {item.name}
      </Button>
    )
  })
}

const Logo = () => {
  return (
    <TouchableIcon 
      image={require('../../assets/rlgalogo_xlarge.png')} 
      style={styles.logo}
      imageStyle={{
        flex:1
      }}
      onPress={() => navigateTo('/')}
    />
  )
}

const AppHeader = withTheme((props:any) => {

  const { items } = props
  return (
    <View>
      <Appbar.Header
        style={{
          height: 100,
          elevation: 25
        }}
      >
        
        <View
          style={{
            flex:1,
            flexDirection:'row-reverse'
          }}
        >
          {setupHeaderItems(items, 'white')}
        </View>

        {/* <Appbar.Action 
          icon="ellipsis-h" 
          color={'white'} 
          onPress={() => console.log('hmm')} 
        /> */}

      </Appbar.Header>
    </View>
    
  )

})


/**
 * sets up the app header and dynamic contents
 */
export const WebContainer:React.FC<IRootContainer> = React.memo((props: any) => {

  const { headerItems } = props
  console.log('WebContainer')


  return (
    <View>
      
      <Logo/>
      <AppHeader items={headerItems}/>

    </View>
  )
})

const styles = {
  logo: {
    top: '15px',
    left: '12.5px',
    width: 200,
    height: 108,
    zIndex: 999,
    position: 'absolute',
    boxShadow: '1px 1px 5px grey'
  },
}