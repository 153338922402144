import * as ActionTypes from 'AppActions/types'
import { IBase } from 'AppModels/base'
import { Validate } from 'AppUtils/validate'
import { AsyncStatus } from 'AppConstants'
export interface IAskCodeData extends IBase {
  name?: string,
  email?: string,
  jobTitle?: string,
  codeTitle?: string,
  question?: string,
  city?: string,
  state?: string,
  codeYear?: string,
  codeSection?: string,
  submitted?: boolean,
  isValidEmail?: boolean,
  asyncStatus?: AsyncStatus
}

const initialState: IAskCodeData = {
  name: '',
  email: '',
  jobTitle: 'Architect',
  codeTitle: '',
  question: '',
  city: '',
  state: '',
  codeYear: '',
  codeSection: '',
  submitted: false,
  isValidEmail: false,
  asyncStatus: AsyncStatus.Unknown
}

export const askCodeReducer = (state = initialState, action: any) => {
  
  const data:IAskCodeData = action.data

  switch (action.type) {

  case ActionTypes.ASK_CODE_FORM_SUBMIT:

    return {
      ...state,
      submitted: true,
      asyncStatus: data.asyncStatus
    }

  case ActionTypes.ASK_CODE_CLEAR_INPUT:
    return {
      ...initialState,
    }

  case ActionTypes.ASK_CODE_ON_INPUT_CHANGE:
    return {
      ...state,
      name: Validate.nullOrUndefined(data.name) ? state.name : data.name,
      email: Validate.nullOrUndefined(data.email) ? state.email : data.email,
      jobTitle: Validate.nullOrUndefined(data.jobTitle) ? state.jobTitle : data.jobTitle,
      codeTitle: Validate.nullOrUndefined(data.codeTitle) ? state.codeTitle : data.codeTitle,
      question: Validate.nullOrUndefined(data.question) ? state.question : data.question,
      city: Validate.nullOrUndefined(data.city) ? state.city : data.city,
      state: Validate.nullOrUndefined(data.state) ? state.state : data.state,
      codeYear: Validate.nullOrUndefined(data.codeYear) ? state.codeYear : data.codeYear,
      codeSection: Validate.nullOrUndefined(data.codeSection) ? state.codeSection : data.codeSection,
      isValidEmail: Validate.nullOrUndefined(data.isValidEmail) ? state.isValidEmail : data.isValidEmail
    }

  default:
    return state
  }
}