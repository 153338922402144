export class BaseModel {
  success: boolean
  message?: string

  constructor(success: boolean, message?: string) {
    this.success = success
    this.message = message
  }

  /**
   * creates an obj notation from the properties
   * @returns {any}
   */
  jsonify(): any {
    return {
      success: this.success,
      message: this.message
    }
  }
}