import React from 'react'
import { View, Text } from 'react-native'

/**
 * Default Container that's displayed when Container-Key Mapping is not found
 */
export const PageNotFoundContainer = () => {

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Text>Default Page Not Found!</Text>
    </View>
  )
}
