import { TOGGLE_MODAL } from 'AppActions/types'

export interface IStarterInfoState {
  modalOpen: boolean
}

const initialState = {
  modalOpen: false
}

export const starterInfoReducer = (state = initialState, action: any) => {

  switch (action.type) {
  case TOGGLE_MODAL: 
    return {
      ...state,
      modalOpen: !state.modalOpen
    }

  default:
    return state
  }

}