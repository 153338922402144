import { dispatch } from 'AppStore/index'
import * as ActionTypes from 'AppActions/types'
import * as ScreenOrientation from 'expo-screen-orientation'


export const updateOrientation = async () => {
  
  dispatch({
    type: ActionTypes.UPDATE_ORIENTATION,
    data: {
      orientation: await ScreenOrientation.getOrientationAsync()
    }
  })
}