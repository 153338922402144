import React from 'react'
import { Picker as RNPicker, View } from 'react-native'
import _ from 'lodash'
import { Title } from 'react-native-paper'

interface IPickerStyle {
  picker: any,
  container: any,
  title: any
}

interface IPicker {
  style?: IPickerStyle,
  data: string[],
  onValueChange: any,
  selectedValue: string,
  title: string
}

const setupPickerItems = (items:string[]) => {
  
  return items.map((item, index) => {
    return (
      <RNPicker.Item
        key={index}
        label={camelCase(item)}
        value={item}
      />
    )
  })
}

/**
 * Capitalizes each word in a given string
 * @param {string} item
 * 
 * @returns {string} 
 */
const camelCase = (item:string) => {
  // split original string by either whitespace or '/' character
  const splitArr = item.split(/[\s\/]/g)
  // grab the values split on previously (either the whitespace or the '/' character)
  const args = item.match(/[\s\/]/g)
  let value = ''

  if (splitArr.length <= 1) {
    return _.capitalize(item)
  }

  // loop through splitArr to capitalize each word, while placing values back in between
  while (splitArr.length) {
    const val = splitArr.shift()
    const arg = args && args.shift()

    value += `${_.capitalize(val)}${arg ? arg : ''}`
  }

  return value
}

export const Picker = (props:IPicker) => {
  const {
    data, style, onValueChange, selectedValue, title 
  } = props

  const containerStyle = {
    ...defaultStyle.container,
    ...(style && style.container)
  }

  const pickerStyle = {
    ...defaultStyle.picker,
    ...(style && style.picker)
  }

  return (
    <View style={containerStyle}>
      <Title
        style={{
          ...defaultStyle.title,
          ...(style && style.title)
        }}
      >
        {title}
      </Title>
      <RNPicker
        selectedValue={selectedValue}
        style={pickerStyle}
        onValueChange={onValueChange}
      >
        {setupPickerItems(data)}
      </RNPicker>
    </View>
  )
}

const defaultStyle = {
  container: {
    flex:1,
    margin: 5
  },
  picker: {
    height: 50
  },
  title: {
    color: 'gray'
  }

}