import React from 'react'
import { View } from 'react-native'

export interface IStaticIFrame {
  height: number,
  src: string,
  style?: any
}

export const StaticIFrame = (props:IStaticIFrame) => {

  const {
    height, src, style
  } = props

  return (
    <View
      style={{
        ...styles.iframeView,
        ...style,
        height
      }}
    >
      <iframe
        style={{
          flex:1
        }}
        scrolling={'no'}
        src={src}
      >
      </iframe>
    </View>
  )
}

const styles = {
  iframeView: {
    flex: 1,
    marginTop: 50, 
    marginBottom: 50, 
    borderStyle: 'solid', 
    borderColor: 'grey', 
    borderWidth: 2
  }
}