import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'

// TODO: move this into someplace more secure
const firebaseConfig = {
  apiKey: 'AIzaSyDQSjS70IHWFwRPWRi3rIQVbzjDhlyu1QQ',
  authDomain: 'specsandcodes-4dfb7.firebaseapp.com',
  databaseURL: 'https://specsandcodes-4dfb7.firebaseio.com',
  projectId: 'specsandcodes-4dfb7',
  storageBucket: 'specsandcodes-4dfb7.appspot.com',
  messagingSenderId: '428824758092',
  appId: '1:428824758092:web:f600b9bca7e87ca94a31e9',
  measurementId: 'G-CN2H091S4K'
}

// to log an event
// this shit throws an error on native. wtf
// https://github.com/firebase/firebase-js-sdk/issues/2801
// firebase.analytics().logEvent('PUSH_BUTTON');
export const firebaseSetup = () => {
  firebase.initializeApp(firebaseConfig)
  firebase.analytics()
}
