import { BaseModel } from './base'


export class NetworkResponseModel extends BaseModel {

  statusCode?: number
  data?: any

  constructor(statusCode?: number, data?: any) {
    super(true)
    this.statusCode = statusCode
    this.data = data
  }
}