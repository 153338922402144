import { createStore, combineReducers } from 'redux'
import * as reducers from 'AppReducers/index'
console.log('reducers', reducers)
const appReducers = combineReducers(reducers)

const rootReducer = (state: any, action: never) => {
  return appReducers(state, action)
}

const Store = createStore(rootReducer)

export const getStore = () => Store
export const getDispatch = () => Store.dispatch
export const dispatch = (action:any) => Store.dispatch(action)