export const JOB_TITLE = {
  ARCHITECT: 'ARCHITECT',
  BUILDING_INSPECTOR: 'BUILDING INSPECTOR',
  BUILDING_OFFICIAL: 'BUILDING OFFICIAL',
  BUILDING_OWNER: 'BUILDING OWNER',
  CONTRACTOR: 'CONTRACTOR',
  DEVELOPER: 'DEVELOPER',
  ENGINEER: 'ENGINEER',
  HOMEOWNER: 'HOMEOWNER',
  MANUFACTURER_SUPPLIER: 'MANUFACTURER/SUPPLIER',
  PLANS_EXAMINER: 'PLANS EXAMINER',
  OTHER: 'OTHER'
}

export const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT'
}