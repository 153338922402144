import { Platform, Linking } from 'react-native'


/**
 * Opens url in a new tab by default if it's web
 * otherwise opens via react-native Linking
 * @param {string} url 
 * @param {boolean} newTab  - whether to open this in a new tab or not
 */
export const openLink = (url: string, newTab=false) => {

  Platform.OS === 'web' && window && newTab
    ? window.open(url, '_blank')
    : Linking.openURL(url)
}
  