import React from 'react'
import { TextInput as RNPTextInput, HelperText } from 'react-native-paper'
import { View } from 'react-native'

interface ITextInput {
  style: any,
  label: string,
  value: string,
  theme?: any,
  onChangeText: any,
  validationText?: string
  showValidationMessage?: boolean,
  multiline?: boolean,
  numberOfLines?: number,
  disabled?: boolean
}

/**
 * Sets up the Validation text component
 * @param props 
 */
const HelperTextComponent = (props:ITextInput) => {
  
  const {
    showValidationMessage,
    validationText
  } = props
  

  return (
    <HelperText
      type="error"
      visible={showValidationMessage || false}
    >
      {validationText}
    </HelperText>
  )
}

/**
 * TextInput with optional validation message
 * @param {ITextInput} props 
 */
export const TextInput = (props: ITextInput) => {

  const {
    style,
    label,
    value,
    theme,
    onChangeText,
    multiline,
    numberOfLines,
    disabled
  } = props

  return (
    <View>
      <HelperTextComponent {...props}/>
      <RNPTextInput
        multiline={multiline}
        numberOfLines={numberOfLines}
        style={style}
        label={label}
        value={value}
        theme={theme}
        disabled={disabled}
        onChangeText={onChangeText}
      />
    </View>

  )
}