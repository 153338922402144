import { dispatch } from 'AppStore/index'
import * as ActionTypes from 'AppActions/types'


/**
 * Toggles side menu - mobile only
 * @returns {void}
 */
export const toggleSideMenu = () => {

  dispatch({
    type: ActionTypes.TOGGLE_SIDE_MENU
  })
}